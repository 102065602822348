// Styling
import { Container } from 'reactstrap';
import styles from './index.module.scss';
import classnames from 'classnames';

//Components
import HeroBanner from '../../components/HeroBanner/HeroBanner';
import MetaHead from '../../components/MetaHead/MetaHead';

import getBaseUrl from '../../libs/getBaseUrl';
import FetchSeries from '../../libs/FetchSeries/FetchSeries';
import FetchFeatured from '../../libs/FetchFeatured/FetchFeatured';

// This gets called on every request
export async function getServerSideProps(context) {
  return {
    props: {
      hostUrl: getBaseUrl(),
      seriesUrl: global.process.env.SERIES_HOST,
      staticAssetUrl: global.process.env.STATIC_ASSETS_CDN_URL,
    },
  };
}

export default function Series({ hostUrl, seriesUrl, staticAssetUrl }) {
  // Needs to change to upcoming index settings. Awaiting API
  const seriesUpcomingFilterParams = {
    size: 6,
    rank: 'closest',
    bq: "(and type:'series' status:'upcoming|live')",
    rankClosest: 'abs(entrytime-time())',
  };

  // Needs to change to upcoming index settings. Awaiting API
  const seriesOnDemandFilterParams = {
    size: 6,
    rank: 'closest',
    bq: "(and type:'series' status:'recorded')",
    rankClosest: 'abs(entrytime-time())',
  };
  const seriesFeaturedFilterParams = {
    start: 0,
    size: 1,
    rank: 'closest',
    bq: `(and type:'series')`,
    rankClosest: 'abs(entrytime-time())',
  };

  // UTM params for featured series.
  const featuredSeriesUTM =
    '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=all-series&utm_content=featured';
  // UTM params for upcoming series.
  const upcomingSeriesUTM =
    '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=all-series&utm_content=upcoming';
  // UTM params for recent series.
  const recentSeriesUTM =
    '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=all-series&utm_content=recent';

  const upcomingSeriesComponentInfo = {
    rowColSm: 12,
    rowColMd: 6,
    rowColXl: 4,
    sectionHeading: true,
    sectionHeadingTitle: 'New episodes coming up',
    sectionHeadingDescription: '',
    sectionHeadingLink: '/series',
    priorityStatus: true,
    seriesUrl,
    seriesUTM: upcomingSeriesUTM,
    footLinkTitle: 'Watch series',
    gridItemName: 'series-item',
    dataBddTitle: 'talks-title-series',
    dataBddDesc: 'talks-card-description-series',
  };

  const recordedSeriesComponentInfo = {
    rowColSm: 12,
    rowColMd: 6,
    rowColXl: 4,
    sectionHeading: true,
    sectionHeadingTitle: 'Catch-up and watch on-demand',
    sectionHeadingDescription: '',
    sectionHeadingLink: '/series',
    priorityStatus: true,
    seriesUrl,
    seriesUTM: recentSeriesUTM,
    footLinkTitle: 'Watch series',
    gridItemName: 'series-item',
    dataBddTitle: 'talks-title-series',
    dataBddDesc: 'talks-card-description-series',
  };

  const featuredSeriesComponentInfo = {
    priorityStatus: true,
    seriesUrl,
    seriesUTM: featuredSeriesUTM,
    type: 'series',
    sectionTitle: 'Series',
  };
  return (
    <>
      <MetaHead
        title="Series - BrightTALK"
        pageName="Series"
        hostUrl={hostUrl}
        staticAssetUrl={staticAssetUrl}
      />
      <HeroBanner
        pageType="page-banner"
        title="Series"
        description="Probing more deeply on the hottest industry topics than a standalone session can, these multi-episode offerings deliver the critical granularity that busy professionals seek — available to watch at your convenience. "
        search={false}
        theme={'dark'}
        hostUrl={hostUrl}
      />
      <Container
        className={classnames('bootstrap-container', styles['series-page'])}
      >
        {FetchFeatured(seriesFeaturedFilterParams, featuredSeriesComponentInfo)}

        {FetchSeries(
          hostUrl,
          seriesUpcomingFilterParams,
          upcomingSeriesComponentInfo
        )}
        {FetchSeries(
          hostUrl,
          seriesOnDemandFilterParams,
          recordedSeriesComponentInfo
        )}
      </Container>
    </>
  );
}
